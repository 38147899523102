<template>
  <el-container class="authmgr-index-main">
    <el-aside class="left-role">
      <std-nav :paths="navData"></std-nav>
      <el-card class="role-list">
        <template #header>
          角色列表
          <div class="btns">
            <el-button type="text" icon="el-icon-plus" size="mini" @click="onCreateRole">新增角色</el-button>
          </div>
        </template>
        <std-table ref="module_table" :cols="roleConfig.cols" :data="roles" disable-selection @selection-change="onShowAuth">
          <template #action="{row}">
            <el-link icon="el-icon-edit" @click="onEditRole(row.EB_ROLE_ID)"></el-link>
            <el-link icon="el-icon-delete" @click="onDeleteRole(row.EB_ROLE_ID)" style="margin-left:5px;"></el-link>
          </template>
        </std-table>
      </el-card>
    </el-aside>
    <el-main>
      <!-- <el-card v-if="list.length==0">
        <div style="color:gray;font-size:13px;">无可用权限,请使用侧边工具导入可用的权限</div>
      </el-card> -->
      <el-card v-if="!ShowAuths" style="min-height:300px;">
        <div style="color:gray;font-size:13px;">请从左侧选择要编辑或查看的角色</div>
      </el-card>
      <el-card v-for="(app,l_index) in auths" :key="l_index" class="auth-list">
        <template #header>
          <span class="appname">{{app.app_name}}</span><span class="version">{{app.version}}</span>
          <div class="header-btns">
            <el-link :underline="false" @click="onSelectAll(app)">全选</el-link>
            <el-link :underline="false" @click="onSelectAnti(app)" style="margin-left:5px;">反选</el-link>
          </div>
        </template>
        <std-table v-bind="tbconfig" :data="app.auths" disable-selection>
          <template #action="{row}">
            <el-checkbox v-model="row.exists" size="mini" :checked="false"><span :class="{isnocheck:!row.exists}">选中</span></el-checkbox>
          </template>
        </std-table>
      </el-card>
      <component v-if="dialog.show" :is="dialog.type" :data="dialog.data" @cancel="onCloseDialog" @success="dialog.event"></component>
    </el-main>
    <el-aside>
      <el-card>
        <template #header>
          角色操作
        </template>
        <el-button type="primary" size="mini" icon="el-icon-check" @click="onSaveRoleAuth" :disabled="currentRole==null">保存角色权限</el-button>
        <hr class="lineh5" />
        <el-button type="primary" size="mini" icon="el-icon-s-opportunity" disabled>查看绑定的用户</el-button>
      </el-card>
      <el-card>
        <template #header>
          权限管理
        </template>
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="onJumpToAdd" disabled>导入新权限</el-button>
        <div style="font-size: 13px;padding: 10px 0;color: #626161;">
          未加入权限管理的地址不会受到权限控制,点击[导入新权限]可以将新的权限纳入管理
        </div>
      </el-card>
    </el-aside>
  </el-container>
</template>
<script>
import winCreateModule from './win-create-module'
import winCreateRole from './win-create-role'
export default {
  components: {
    winCreateModule, winCreateRole
  },
  data() {
    return {
      navData: [
        { text: '系统管理', path: '/SysMgr' },
        '权限设定'
      ],
      roles: [],
      auths: {},
      dialog: {
        show: false,
        type: '',
        data: {},
        event: null
      },
      currentRole: null
    }
  },
  computed: {
    tbconfig() {
      return {
        cols: [
          { label: '地址', prop: 'path' },
          { label: '描述', prop: 'desc' },
          { format: 'action', width: 100 }
        ]
      }
    },
    roleConfig() {
      return {
        cols: [
          { label: '角色名', prop: 'EB_ROLE_DESC' },
          { format: 'action', width: 60 }
        ]
      }
    },
    ShowAuths() {
      return this.auths.length > 0;
    }
  },
  methods: {
    onCloseDialog() {
      this.dialog.show = false
    },
    onJumpToAdd() {
      this.$router.push({ path: '/AuthMgr/import' })
    },
    onCreateRole() {
      let me = this
      this.$set(this, 'dialog', {
        show: true,
        type: 'win-create-role',
        data: {
          isCreate: true
        },
        event() {
          me.$set(me, 'dialog', {
            show: false
          })
          me.RefreshRoles()
        }
      })
    },
    onEditRole(role_id) {
      let me = this
      this.$set(this, 'dialog', {
        show: true,
        type: 'win-create-role',
        data: {
          isCreate: false,
          role_id: role_id
        },
        event() {
          me.$set(me, 'dialog', {
            show: false
          })
          me.RefreshRoles()
        }
      })
    },
    onDeleteRole(role_id) {
      let me = this
      this.Bus.msg_confirm('确定要删除该角色吗?', _ => {
        this.Bus.ActionFunc("Role-DeleteDetail", {
          role_id: role_id,
        }, data => {
          me.RefreshRoles();
        })
      })
    },
    RefreshRoles() {
      this.Bus.ActionFunc("Role-GetList", {}, data => {
        this.$set(this, 'roles', data.list)
        this.$set(this, 'auths', [])
      });
    },
    onShowAuth(selection) {
      if (selection.length != 1) {
        this.$set(this, 'currentRole', null)
        return
      }
      let role_id = selection[0].EB_ROLE_ID
      this.currentRole = role_id
      this.Bus.ActionFunc('Role-GetAuthData', {
        role_id
      }, data => {
        this.$set(this, 'auths', data.list)
      })
    },
    onSaveRoleAuth() {
      let me = this
      
      this.Bus.msg_confirm(`确定要更新该角色的权限吗?`, _ => {
        let role_id = this.currentRole
        let paths = {}
        for (const app of this.auths) {
          let appid = app.appid
          for (const auth of app.auths) {
            if (auth.exists) {
              if (!paths[appid]) {
                paths[appid] = []
              }
              paths[appid].push(auth.path)
            }
          }
        }
        this.Bus.ActionFunc('Role-ChangeAuthData', {
          role_id: role_id,
          paths: paths
        }, data => {
          me.Bus.msg_success('保存成功!')
        })
      })
    },
    onSelectAll(app) {
      for (const path of app.auths) {
        this.$set(path, 'exists', true)
      }
    },
    onSelectAnti(app) {
      for (const path of app.auths) {
        this.$set(path, 'exists', !path.exists)
      }
    }
  },
  mounted() {
    this.RefreshRoles();
  }
}
</script>
<style lang="scss">
.authmgr-index-main {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  > .el-main {
    padding: 25px 10px 10px 10px !important;

    > .el-card {
      margin-bottom: 10px;
    }

    > .auth-list {
      .appname{
        font-size: 18px;
      }
      .version{
        color:gray;
        font-size: 12px;
        margin-left: 5px;
      }
      .el-card__body {
        padding: 0;
      }
      .header-btns {
        float: right;
      }
      .isnocheck {
        color: #c4c4c4;
      }
    }
  }
  > .el-aside {
    > .el-card + .el-card {
      margin-top: 10px;
    }
  }

  .left-role {
    overflow: visible;
    .role-list {
      .btns {
        float: right;
      }
      height: calc(100% - 10px);
      .el-card__body {
        padding: 0;
        height: 100%;
        .std-table {
          width: 100%;
        }
      }
    }
  }
}
</style>