<template>
  <el-dialog title="角色管理" :visible="true" :before-close="onClose" width="500px" class="win-create-role">
    <el-container>
      <el-main style="padding:0">
        <el-form ref="detail_form" class="form-jg5" label-width="100px" label-position="right" size="mini">
          <el-form-item label="角色编号">
            <el-input v-model.trim="form.role_id" :disabled="!isCreate"></el-input>
          </el-form-item>
          <el-form-item label="角色名称">
            <el-input v-model.trim="form.role_desc"></el-input>
          </el-form-item>
          <el-form-item label="角色名称">
            <el-input type="textarea" :rows="2" v-model.trim="form.remark"></el-input>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer>
        <el-button type="primary" size="mini" icon="el-icon-s-claim" @click="onSave">提交</el-button>
        <el-button type="primary" size="mini" icon="el-icon-refresh-right" @click="onClose">取消</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      form: {
        role_id: '',
        role_desc: '',
        remark: ''
      }
    }
  },
  computed: {
    isCreate() {
      return !!this.data.isCreate
    }
  },
  methods: {
    onClose() {
      this.$emit('cancel')
    },
    onSave() {
      let form = this.form
      let postData = {
        role_id: form.role_id,
        role_desc: form.role_desc,
        remark: form.remark
      }
      let valiResult = this.Bus.validData(postData, {
        require: [
          { prop: 'role_id', name: '角色ID' },
          { prop: 'role_desc', name: '角色名称' }
        ]
      })
      if (!valiResult) {
        return
      }

      let create = this.data.isCreate
      this.Bus.ActionFunc(create ? `Role-CreateDetail` : `Role-UpdateDetail`, postData, data => {
        this.Bus.msg_success('保存成功!')
        this.$emit('success')
      });
    },
  },
  mounted() {
    let me=this
    if (!this.data.isCreate) {
      me.Bus.ActionFunc("Role-GetDetail", { role_id: me.data.role_id }, data => {
        me.$set(me, 'form', {
          role_id: data.role_id,
          role_desc: data.role_desc,
          remark: data.remark
        })
      })
    }
  }
}
</script>
<style lang="scss">
.win-create-role {
  .el-dialog {
    > .el-dialog__body {
      .el-container {
        min-height: 200px;
        > .el-footer {
          text-align: right;
          height: 40px !important;
        }
      }
    }
  }
}
</style>
