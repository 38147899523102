<template>
  <el-dialog title="导入模块" :visible="true" :before-close="onClose" width="500px" class="win-create-module">
    <el-container>
      <el-main style="padding:0">
        <el-form ref="detail_form" class="form-jg5" label-width="100px" label-position="right" size="mini">
          <el-form-item label="来源">
            <el-select v-model="form.from_type" placeholder="请选择">
              <el-option v-for="(value,name) in formTypes" :key="name" :label="value" :value="name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择模块" v-if="form.from_type=='api'">
            <el-select v-model="form.module_id" placeholder="请选择" :disabled="form.all_module">
              <el-option v-for="item in apimodules" :key="item" :label="item" :value="item">
              </el-option>
            </el-select><br />
            <el-checkbox v-model="form.all_module" size="mini" :checked="false">读取全部</el-checkbox>
          </el-form-item>
          <el-form-item label="文件上传" v-if="form.from_type=='upload'">
            <el-upload class="upload-demo" v-bind="uploadConfig" :file-list="upload_file" :data="upload_data">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传Json格式文件,后缀可以为.json或.txt</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="链接" v-if="form.from_type=='url'">
            <el-input v-model.trim="form.url"></el-input>
          </el-form-item>
          <el-form-item>
            <span style="color: gray;font-size: 13px;">来源需为swagger文档</span>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer>
        <el-button type="primary" size="mini" icon="el-icon-s-claim" @click="onSave">提交</el-button>
        <el-button type="primary" size="mini" icon="el-icon-refresh-right" @click="onClose">取消</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>
<script>
export default {
  data() {
    let uploadFile = []
    return {
      form: {
        from_type: 'api',
        url: '',
        module_id:'',
        all_module:false
      },
      apimodules: [],
      upload_file: uploadFile,
      upload_data: {},
      uploadConfig: {
        drag: true,
        action: this.UploadPath,
        'auto-upload': false,
        ref: 'upload',
        accept: 'application/json,text/plain',
      }
    }
  },
  computed: {
    UploadPath() {
      return this.$store.getters.ApiPath + '/values/dft/upload'
    },
    formTypes() {
      return {
        upload: '文档上传',
        api: 'Api中检测',
        url: "按网址读取"
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('cancel')
    },
    onSave() {
      let form = this.form
      if (this.form.from_type == 'api') {
        this.Bus.ActionFunc("Auth-ImportFromApi", {}, data => {
          //this.$emit('success', )
        })
      }
      // let vali = this.Bus.validData(postData, {
      //   require: [
      //     { prop: 'module_id', name: '模块ID' },
      //     { prop: 'module_desc', name: '模块描述' }
      //   ]
      // })
      // if (!vali) {
      //   return
      // }


    }
  },
  mounted() {
    this.Bus.ActionFunc("Auth-GetAllModuleName", {}, data => {
      this.$set(this, 'apimodules', data.list)
    })
  }
}
</script>
<style lang="scss">
.win-create-module {
  .el-dialog {
    > .el-dialog__body {
      .el-container {
        min-height: 200px;
        > .el-footer {
          text-align: right;
          height: 40px !important;
        }
      }
    }
  }
}
</style>